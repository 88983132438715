import React from 'react';

// Dodane testowo slajdy z rozdziału pierwszego!!!
// TODO: zmienić na właściwe slajdy
import Slide1 from 'src/slides/desktop/hu/chapter_3/sub_2/slide1';
import Slide2 from 'src/slides/desktop/hu/chapter_3/sub_2/slide2';
// import Slide

import WrapperMobile from 'src/slides/mobile/hu/chapter_3/sub_2/wrapperMobile';
import { isMobile } from 'react-device-detect';
import SEO from 'src/components/_shared/seo';
import MainSlider from 'src/components/desktop/mainSlider/mainSlider.jsx';


const allSlides = [<Slide1 />, <Slide2 />];

const exceptions = [];

const Desktop = () => (
  <>
    <SEO title="Józef Piłsudski | A varsói csata" description="Az ember, aki megállította a bolsevikokat. Ismerd meg történetét." lang="hu" />
    <MainSlider slides={allSlides} exceptions={exceptions} />
  </>
);

const Mobile = () => (
  <>
    <SEO title="Józef Piłsudski | A varsói csata" description="Az ember, aki megállította a bolsevikokat. Ismerd meg történetét." lang="hu" />
    <WrapperMobile />
  </>
);

const Page = () => {
  if (isMobile) return <Mobile />;
  return <Desktop />;
};

export default Page;
