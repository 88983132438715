import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/desktop/_templates/chapter_3/sub_2/slide2';

const Slide = () => {
  const query = useStaticQuery(graphql`
  query {
    leftColumn: mdx(frontmatter: {language: {eq: "HU"}, title: {eq: "slide-3-2-2"}}) {
        body
      }
  }
  `);
  return (
    <Template query={query} />
  );
};


export default Slide;
